import MultiFactorAuthenticationSend from '@/company-portal/pages/MultiFactorAuthenticationSend.vue';
import MultiFactorAuthenticationVerify from '@/company-portal/pages/MultiFactorAuthenticationVerify.vue';
import CompanyPortalLayout from '@/layouts/CompanyPortalLayout.vue';
import Avatar from 'primevue/avatar';
import Button from 'primevue/button';
import Menu from 'primevue/menu';
import Menubar from 'primevue/menubar';

import IdentityVerificationReportWrapper from './Components/IdentityVerificationReport/IdentityVerificationReportWrapper.vue';

import IdentityVerificationReviewWrapper from './Components/IdentityVerificationReview/IdentityVerificationReviewWrapper.vue';
import ClHeader from './Navigation/ClHeader.vue';
import CompanyNavigation from './Navigation/top-nav/CompanyNavigation.vue';
import EmployeeNavigation from './Navigation/top-nav/EmployeeNavigation.vue';
import ShadowButton from './Navigation/top-nav/ShadowButton.vue';
import ShadowWarning from './Navigation/top-nav/ShadowWarning.vue';
import AccountActions from './PageSections/AccountActions.vue';

/*
  This list contains components that are used as entry points when initVueSprinkle is used.
  Descending components are resolved as usual
  When using them in blade templates, declare the component tag using <kebab-case />
*/
export const allComponents = {
    ClHeader,
    CompanyPortalLayout,
    EmployeeNavigation,
    CompanyNavigation,
    AccountActions,
    Avatar,
    Button,
    Menu,
    Menubar,
    ShadowButton,
    ShadowWarning,
    MultiFactorAuthenticationSend,
    MultiFactorAuthenticationVerify,
    IdentityVerificationReportWrapper,
    IdentityVerificationReviewWrapper,
};
