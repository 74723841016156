<template>
    <Dialog
        v-model:visible="modalOpen"
        header=" "
        modal
        appendTo="body"
        @hide="cleanupCancelUpdate"
        dismissableMask
        :draggable="false"
        :style="{ width: '500px', 'max-width': '672px' }"
    >
        <div class="flex flex-col p-6">
            <slot name="header">
                <template v-if="context === 'employee'">
                    <h3 class="text-center font-semibold text-xl">Closinglock Review</h3>
                    <div class="text-base font-normal text-gray-400 mb-9 text-center">
                        Decide if Closinglock will cover transactions that use this ID verification.
                    </div>
                </template>
                <template v-else>
                    <h3 class="text-center font-semibold text-xl">Are you sure?</h3>
                    <div class="text-basefont-normal text-gray-400 mb-9 text-center">
                        By overriding the failed result, you have determined this client is not a risk to continue with the
                        transaction.
                    </div>
                </template>
            </slot>
            <div
                class="my-2"
                v-if="props.context === 'employee'"
            >
                <h4 class="text-neutral-600 text-base">Employee:</h4>
                <input
                    class="w-full bg-gray-100 border-gray-300 rounded-md px-3 py-2"
                    type="text"
                    :value="currentUserService.currentUser ? currentUserService.currentUser.name : ''"
                    readonly
                />
            </div>
            <div
                class="my-2"
                v-if="props.context === 'employee'"
            >
                <h4 class="text-neutral-600 text-base">Result:</h4>
                <div class="flex">
                    <div
                        v-for="(option, idx) in radioOptions"
                        :key="idx"
                        class="items-center mb-4 mr-4"
                    >
                        <input
                            class="w-4 h-4 mr-2 focus:ring-primary-500 text-primary-600 border-gray-300"
                            type="radio"
                            :id="option.id"
                            :name="option.value"
                            :value="option.value"
                            v-model="selectedOption"
                        />
                        <label :for="option.id">{{ option.label }}</label>
                    </div>
                </div>
            </div>
            <div class="my-2">
                <h4 class="text-neutral-600 text-sm">Reasons why:</h4>
                <textarea
                    v-model="overrideReason"
                    class="w-full border-gray-300 rounded-md px-3 py-2 mb-4"
                    rows="4"
                ></textarea>
            </div>
            <div class="flex flex-col gap-2 font-medium">
                <Button @click="submitNewResult">
                    <slot name="success-button">{{ confirmActionText }}</slot>
                </Button>
                <Button
                    @click="cleanupCancelUpdate"
                    outlined
                >
                    <slot name="cancel-button">{{ cancelActionText }}</slot>
                </Button>
            </div>
        </div>
    </Dialog>
</template>

<script setup lang="ts">
import type { IDVReviewEntry } from '@/api/interfaces/identity-verification.api';
import { IDVStatus } from '@/api/interfaces/identity-verification.api';
import { useCurrentUserService } from '@/services/current-user.service';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import { onMounted, ref, watch } from 'vue';
const currentUserService = useCurrentUserService();

type IdvStatusAvailableOption = IDVStatus.amended | IDVStatus.confirmed_failed;

interface RadioOption {
    id: string;
    label: string;
    value: IdvStatusAvailableOption;
}

type IDVUpdateEmitPayload = {
    idv: IDVReviewEntry['id'];
    status: IDVStatus;
    reason: string;
};

const radioOptions: RadioOption[] = [
    {
        id: 'pass',
        label: 'Pass',
        value: IDVStatus.amended,
    },
    {
        id: 'fail',
        label: 'Fail',
        value: IDVStatus.confirmed_failed,
    },
];

const props = withDefaults(
    defineProps<{
        idvEntryToUpdate: IDVReviewEntry['id'] | null;
        context?: 'employee' | 'admin';
        confirmActionText?: string;
        cancelActionText?: string;
    }>(),
    { idvEntryToUpdate: null, context: 'employee', confirmActionText: 'Submit New Result', cancelActionText: 'Cancel' },
);

const modalOpen = ref<boolean>(!!props.idvEntryToUpdate);
const overrideReason = ref<string>('');

onMounted(() => {
    if (props.idvEntryToUpdate) {
        selectedOption.value = IDVStatus.amended;
    }
});

watch(
    () => props.idvEntryToUpdate,
    () => {
        modalOpen.value = !!props.idvEntryToUpdate;
    },
);

const emit = defineEmits<{
    (event: 'update-idv-status', payload: IDVUpdateEmitPayload): void;
    (event: 'cancel-idv-update'): void;
}>();

const selectedOption = ref<IdvStatusAvailableOption>(IDVStatus.amended);

const submitNewResult = () => {
    if (!props.idvEntryToUpdate) return;
    emit('update-idv-status', {
        idv: props.idvEntryToUpdate,
        status: selectedOption.value,
        reason: overrideReason.value,
    });
    modalOpen.value = false;
};

const cleanupCancelUpdate = () => {
    // close the modal
    modalOpen.value = false;
    // emit the cancel event to the parent
    overrideReason.value = '';
    selectedOption.value = IDVStatus.amended;
    emit('cancel-idv-update');
};
</script>

<style scoped></style>
