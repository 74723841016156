<script lang="ts" setup>
import { CompanyAccount } from '@/api/interfaces/company-account.api';
import { EscrowAccountAddress } from '@/api/interfaces/escrow-account.api';
// prettier-ignore
import {
    EscrowAccountBranchExtended,
    EscrowAccountStatusDisplayLabel,
    EscrowAccountWireStatus,
    FlatWireDoc,
} from '@/interfaces/payment-settings/escrow-account';
import { useEscrowAccountService } from '@/services/escrow-account.service';
import { API_DATE_FORMAT, DAY_MONTH_YEAR_FORMAT, formatDate } from '@/shared/utils/format-date';
import dayjs from 'dayjs';
import Checkbox from 'primevue/checkbox';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import Dialog from 'primevue/dialog';
import { computed, nextTick, reactive, ref, watch } from 'vue';
import FormEditAccountNumber from './forms/FormEditAccountNumber.vue';
import FormEditBranchInfo from './forms/FormEditBranchInfo.vue';
import FormEditRoutingNumber from './forms/FormEditRoutingNumber.vue';
import FormEditStartDate from './forms/FormEditStartDate.vue';
import FormEditWireDocNotes from './forms/FormEditWireDocNotes.vue';
// prettier-ignore
import {
    EditAccountNumberSubmitPayload,
    EditBranchInfoPayload,
    EditRoutingNumberSubmitPayload,
    EditStartDateSubmitPayload,
    EditWireDocNotesPayload,
} from '../forms/form-payloads';

import Toast from 'primevue/toast';
import { useToast } from 'primevue/usetoast';

const toast = useToast();

const props = defineProps<{ escrowAccountBranches: EscrowAccountBranchExtended[]; accountId: CompanyAccount['id'] }>();

const escrowAccountService = useEscrowAccountService();

/** Creates a flat list of all the wire documents with the respective data from the branch */
const flatWireDocList = computed<(FlatWireDoc & { selected: boolean })[]>(() => {
    const wireDocs = props.escrowAccountBranches
        ?.map(branch => [
            ...branch.wire_docs.map(doc => ({
                ...doc,
                selected: false,
                branchName: branch.name,
                branchId: branch.id,
                contact: branch.contact,
                address: branch.address,
            })),
        ])
        .reduce((docListA, docListB) => [...docListA, ...docListB], []);
    return wireDocs || [];
});

// list possible templates for config consistency check against template
type CelTemplate = 'simple' | 'status-color' | 'wire-link' | 'modal-action';

type EscrowTableProperty = keyof FlatWireDoc;
type ObjectField = Record<string, string>;
type EscrowTableSetting = {
    field: EscrowTableProperty;
    header: string;
    formatter: (fieldValue: unknown) => string | number | ObjectField;
    template?: CelTemplate;
    columnClass: string;
    modalAction?: (row: FlatWireDoc) => void;
};

const currentWireDocUnderEdit = ref<FlatWireDoc>();
const initialModalState = {
    editAccountNumber: false,
    editRoutingNumber: false,
    editBranchInfo: false,
    editStartDate: false,
    editNotes: false,
};
const modalVisibleState = reactive({ ...initialModalState });

function closeModals() {
    Object.keys(initialModalState).forEach(k => (modalVisibleState[k as keyof typeof initialModalState] = false));
}

function openModal(modalAlias: keyof typeof modalVisibleState) {
    closeModals();
    modalVisibleState[modalAlias] = true;
}

const columnsSettings: EscrowTableSetting[] = [
    {
        field: 'status',
        header: 'Status',
        formatter: status => {
            return EscrowAccountStatusDisplayLabel[status as EscrowAccountWireStatus];
        },
        template: 'status-color',
        columnClass: 'w-1/12',
    },
    {
        field: 'id',
        header: 'Wire Instruction',
        formatter: id => {
            const wireDoc = flatWireDocList.value.find(doc => doc.id === id);
            return {
                url: `${location.origin}/api/payments/downloadwiredoc/${wireDoc?.id}`,
                label: wireDoc?.name,
                branchName: wireDoc?.branchName,
            } as ObjectField;
        },
        template: 'wire-link',
        columnClass: 'w-4/12',
    },
    {
        field: 'address',
        header: 'Address',
        formatter: address => {
            const castAddress = address as EscrowAccountAddress;
            const addressComplete = castAddress?.address_line_1 && castAddress?.city && castAddress?.state && castAddress?.zip;
            return { text: addressComplete ? 'Yes' : '', class: 'text-primary-600-brand' } as ObjectField;
        },
        template: 'modal-action',
        columnClass: 'w-1/12',
        modalAction: escrowRowData => {
            openModal('editBranchInfo');
            currentWireDocUnderEdit.value = escrowRowData;
        },
    },

    {
        field: 'account_number',
        header: 'Account Number',
        formatter: accNumber => {
            return { text: accNumber, class: '' } as ObjectField;
        },
        template: 'modal-action',
        columnClass: 'w-2/14',
        modalAction: escrowRowData => {
            if (escrowRowData.account_number) {
                toast.add({
                    severity: 'info',
                    summary: 'Account Number is already set',
                    detail: 'You can not edit an existing Account Number',
                    life: 2000,
                });
                return;
            }
            openModal('editAccountNumber');
            currentWireDocUnderEdit.value = escrowRowData;
        },
    },
    {
        field: 'routing_number',
        header: 'Routing Number',
        formatter: routingNumber => {
            return { text: routingNumber, class: '' } as ObjectField;
        },
        template: 'modal-action',
        columnClass: 'w-2/14',
        modalAction: escrowRowData => {
            if (escrowRowData.routing_number) {
                toast.add({
                    severity: 'info',
                    summary: 'Routing Number is already set',
                    detail: 'You can not edit an existing Routing Number',
                    life: 2000,
                });
                return;
            }
            openModal('editRoutingNumber');
            currentWireDocUnderEdit.value = escrowRowData;
        },
    },
    {
        field: 'payment_start_date',
        header: 'Start Date',
        formatter: startDate => formatDate(startDate as Date, DAY_MONTH_YEAR_FORMAT, 'None'),
        template: 'simple',
        columnClass: 'w-1/14',
        modalAction: escrowRowData => {
            openModal('editStartDate');
            currentWireDocUnderEdit.value = escrowRowData;
        },
    },
    {
        field: 'notes',
        header: 'Notes',
        formatter: notes => {
            if (!notes || notes.length == 0) return { text: '', class: '' } as ObjectField;
            const notesStr = typeof notes === 'string' ? notes : JSON.stringify(notes);
            const notesCountText = 'View ' + JSON.parse(notesStr).length.toString() + ' notes';
            return { text: notesCountText, class: 'text-primary-600-brand' } as ObjectField;
        },
        template: 'modal-action',
        columnClass: 'w-3/14',
        modalAction: escrowRowData => {
            openModal('editNotes');
            currentWireDocUnderEdit.value = escrowRowData;
        },
    },
];

function resolveStatusColor(status: EscrowAccountWireStatus) {
    const cellTextClass = {
        [EscrowAccountWireStatus.ENABLED]: 'text-secondary-600-brand',
        [EscrowAccountWireStatus.PENDING]: 'text-warning-500',
        [EscrowAccountWireStatus.LOCKED]: 'text-back',
        [EscrowAccountWireStatus.DISABLED]: 'text-back',
        [EscrowAccountWireStatus.UNKNOWN]: 'text-black',
    } as const;
    return cellTextClass[status];
}

const selectedWireDocs = ref<FlatWireDoc[]>([]);
watch([escrowAccountService.selectedWireDocs], ([newSelection]) => {
    const currentSelectionIds = new Set(newSelection.map(doc => doc.id));
    selectedWireDocs.value = selectedWireDocs.value.filter(doc => currentSelectionIds.has(doc.id));
});

function getUnlockedDocs() {
    return flatWireDocList.value.filter(item => item.status !== EscrowAccountWireStatus.LOCKED);
}

function getLockedDocs() {
    return flatWireDocList.value.filter(item => item.status === EscrowAccountWireStatus.LOCKED);
}

function onRowSelectAll() {
    nextTick(() => {
        escrowAccountService.selectWireDocs(getUnlockedDocs());
        escrowAccountService.deselectWireDocs(getLockedDocs());
        selectedWireDocs.value = getUnlockedDocs();
    });
}

function onRowUnselectAll() {
    escrowAccountService.deselectWireDocs(flatWireDocList.value);
}

function handleSelectionChange() {
    escrowAccountService.deselectWireDocs(flatWireDocList.value);
    escrowAccountService.selectWireDocs(selectedWireDocs.value);
    escrowAccountService.deselectWireDocs(getLockedDocs());
}

function formatDefaultBankingInformation(flatWireDoc: FlatWireDoc) {
    return {
        wire_doc_id: flatWireDoc.id,
        account_number: flatWireDoc.account_number,
        routing_number: flatWireDoc.routing_number,
        enable_for_closinglock_payments: flatWireDoc.enable_for_closinglock_payments,
        payment_start_date: formatDate(dayjs(flatWireDoc.payment_start_date).toDate(), API_DATE_FORMAT),
    };
}

function handleStartDateChange({ flatWireDoc, values }: EditStartDateSubmitPayload) {
    modalVisibleState.editStartDate = false;
    escrowAccountService.updateBankingInformation(props.accountId, {
        ...formatDefaultBankingInformation(flatWireDoc),
        payment_start_date: formatDate(dayjs(values.startDate).toDate(), API_DATE_FORMAT),
        enable_for_closinglock_payments: true,
    });
}

function handleRoutingNumberChange({ flatWireDoc, values }: EditRoutingNumberSubmitPayload) {
    modalVisibleState.editRoutingNumber = false;
    escrowAccountService.updateBankingInformation(props.accountId, {
        ...formatDefaultBankingInformation(flatWireDoc),
        routing_number: values.routingNumber,
    });
}

function handleAccountNumberChange({ flatWireDoc, values }: EditAccountNumberSubmitPayload) {
    modalVisibleState.editAccountNumber = false;
    escrowAccountService.updateBankingInformation(props.accountId, {
        ...formatDefaultBankingInformation(flatWireDoc),
        account_number: values.accountNumber,
    });
}

function handleBranchInformationChange({ flatWireDoc, values }: EditBranchInfoPayload) {
    modalVisibleState.editBranchInfo = false;
    escrowAccountService.updateBranchInformation(props.accountId, flatWireDoc.branchId, {
        name: flatWireDoc.branchName,
        phone: values.phone,
        contact_email: values.email,
        address_line_1: values.addressLineOne,
        address_line_2: values.addressLineTwo,
        address_zip: values.addressZip,
        address_city: values.addressCity,
        address_state: values.addressState,
    });
}

function handleWireDocNotesChange({ flatWireDoc, newNote }: EditWireDocNotesPayload) {
    modalVisibleState.editNotes = false;
    escrowAccountService.updateWireDocNotes(props.accountId, flatWireDoc.id, newNote);
}
</script>

<template>
    <Toast />
    <DataTable
        v-if="flatWireDocList.length >= 1"
        :value="flatWireDocList"
        dataKey="id"
        v-model:selection="selectedWireDocs"
        @row-select-all="onRowSelectAll"
        @row-unselect-all="onRowUnselectAll"
        :paginator="flatWireDocList.length > 10"
        :rows="10"
        :pt="{
            bodyRow({ props, context }) {
                const items = props.value as FlatWireDoc[];
                const currentItem = items[context.index];
                return currentItem?.status === EscrowAccountWireStatus.LOCKED ? 'bg-neutral-100' : 'bg-white';
            },
        }"
    >
        <Column
            selectionMode="multiple"
            :class="'pb-3 w-[10px]'"
        >
            <template #body="{ data }">
                <Checkbox
                    :inputId="`${data['id']}`"
                    v-model="selectedWireDocs"
                    :value="data"
                    :disabled="(data as FlatWireDoc).status === EscrowAccountWireStatus.LOCKED"
                    name="wire-select"
                    :title="
                        (data as FlatWireDoc).status === EscrowAccountWireStatus.LOCKED ? 'Locked items can not be selected' : ''
                    "
                    @change="handleSelectionChange"
                ></Checkbox>
            </template>
        </Column>
        <Column
            v-for="col of columnsSettings"
            :key="col.field"
            :field="col.field"
            :header="col.header"
            :class="col.columnClass"
        >
            <template #body="{ data, field }">
                <template v-if="!col.template">
                    {{ data[field] }}
                </template>
                <template v-if="col.template === 'simple'">
                    {{ col.formatter(data[field]) }}
                </template>
                <template v-if="col.template === 'status-color'">
                    <span :class="resolveStatusColor(data[field]) + ' text-sm '">
                        {{ col.formatter(data[field]) }}
                    </span>
                </template>
                <template v-if="col.template === 'wire-link'">
                    <div class="text-sm">
                        <div class="font-light text-neutral-500">
                            {{ (col.formatter(data[field]) as ObjectField).branchName }}
                        </div>
                        <a
                            :href="(col.formatter(data[field]) as ObjectField).url"
                            target="_blank"
                            class="text-primary-600-brand"
                        >
                            {{ (col.formatter(data[field]) as ObjectField).label }}
                        </a>
                    </div>
                </template>
                <template v-if="col.template === 'modal-action'">
                    <div
                        class="cursor-pointer w-full min-h-1"
                        @click="() => (col.modalAction ? col.modalAction(data) : undefined)"
                    >
                        <div
                            v-if="(col.formatter(data[field]) as ObjectField).text"
                            :class="(col.formatter(data[field]) as ObjectField).class"
                        >
                            {{ (col.formatter(data[field]) as ObjectField).text }}
                        </div>
                        <div
                            v-else
                            class="text-blue text-primary-600-brand"
                        >
                            None (add)
                        </div>
                    </div>
                </template>
            </template>
        </Column>
    </DataTable>
    <p v-else>There are no available wire instructions for this company.</p>
    <Dialog
        v-model:visible="modalVisibleState.editAccountNumber"
        header=" "
        modal
        :style="{ width: '500px', 'max-width': '672px' }"
        dismissableMask
        :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
        :draggable="false"
    >
        <FormEditAccountNumber
            v-if="currentWireDocUnderEdit"
            :flat-wire-doc="currentWireDocUnderEdit"
            @submit="handleAccountNumberChange"
            @cancel="closeModals"
        />
    </Dialog>
    <Dialog
        v-model:visible="modalVisibleState.editRoutingNumber"
        header=" "
        modal
        :style="{ width: '500px', 'max-width': '672px' }"
        dismissableMask
        :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
        :draggable="false"
    >
        <FormEditRoutingNumber
            v-if="currentWireDocUnderEdit"
            :flat-wire-doc="currentWireDocUnderEdit"
            @submit="handleRoutingNumberChange"
            @cancel="closeModals"
        />
    </Dialog>
    <Dialog
        v-model:visible="modalVisibleState.editBranchInfo"
        header=" "
        modal
        :style="{ width: '500px', 'max-width': '672px' }"
        dismissableMask
        :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
        :draggable="false"
    >
        <FormEditBranchInfo
            v-if="currentWireDocUnderEdit"
            :flat-wire-doc="currentWireDocUnderEdit"
            @submit="handleBranchInformationChange"
            @cancel="closeModals"
        />
    </Dialog>
    <Dialog
        v-model:visible="modalVisibleState.editStartDate"
        header=" "
        modal
        :style="{ width: '500px', 'max-width': '672px' }"
        dismissableMask
        :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
        :draggable="false"
    >
        <FormEditStartDate
            v-if="currentWireDocUnderEdit"
            :flat-wire-doc="currentWireDocUnderEdit"
            :suggested-start-date="currentWireDocUnderEdit.payment_start_date || new Date()"
            @submit="handleStartDateChange"
            @cancel="closeModals"
        />
    </Dialog>
    <Dialog
        v-model:visible="modalVisibleState.editNotes"
        header=" "
        modal
        :style="{ width: '500px', 'max-width': '672px' }"
        dismissableMask
        :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
        :draggable="false"
    >
        <FormEditWireDocNotes
            v-if="currentWireDocUnderEdit"
            :flat-wire-doc="currentWireDocUnderEdit"
            :current-notes="currentWireDocUnderEdit.notes"
            @submit="handleWireDocNotesChange"
            @cancel="closeModals"
        />
    </Dialog>
</template>
